import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'

class Page extends React.Component {

    render() {

        return (

          <Layout location={this.props.location}>

              <SEO
                title="Guide to starting a Podcast in 2021"
                description="Step by step podcast guide that will provide all the information you need to get going, from zero to hero!"
              />

              <article>


                  <p>
                      <img src="/images/how-to-start-a-podcast.jpg" alt="How to start a podcast banner"/>
                  </p>

                  <h1>Guide to starting a Podcast in 2021</h1>
                  <p>Podcasts have become incredibly popular over the last few years. Now, in the UK alone, more than 6
                      million of us tune into a podcast on a weekly basis. If you enjoy listening to podcasts, or you’ve
                      seen others reap the personal, financial or business rewards of putting together and sharing
                      podcasts, you might be wondering how to launch your own offering. If this idea appeals, here’s a
                      useful podcast guide that will provide all the information you need to get going.</p>

                  <h2>The planning process</h2>

                  <p>Before you go in all guns blazing ready to get started podcasting, it’s hugely beneficial to take
                      some time to plan ahead. Whether you’re using your podcast for business reasons, you want to share
                      opinions and views, or you’re keen to interact with people online and put a subject matter under
                      the spotlight, you need to think about what you’re going to talk about, who you’re going to talk
                      to, and how you’re going to make your podcast popular.</p>


                  <ol>
                      <li>
                          <strong>Finding your niche</strong>
                          <p>One of the most important considerations for anyone who wants to start a podcast is the
                              theme. What is going to provide the meat of your podcast? What are you going to talk
                              about? What kinds of subjects do you want to cover? To answer these questions, it’s wise
                              to focus on issues or areas that interest you. What are you passionate about? What do you
                              feel like you really want to discuss or put out there into the ether? You can cover a
                              series of different topics, but your podcast should have an overriding theme, which
                              enables the listener to identify what your podcast is all about. You can cover anything
                              from mental health and wellbeing, fitness, and baking to weight-lifting, driving, gaming,
                              and football. The choice is yours.</p>
                      </li>
                      <li>
                          <strong>Establishing your objective</strong>
                          <p>The next step to think about is establishing your goal. What do you want to achieve from
                              your podcast, and what are you hoping to gain from it? Are you starting a podcast to
                              promote your business? Do you work in marketing? Are you interested in online engagement?
                              Do you have a personal goal to talk about issues that affect or interest you? Are you keen
                              to get a message out there or to help others by opening up or offering tips? Is podcasting
                              set to be a new hobby? Once you’ve identified your objective, you’ll find it easier to put
                              your podcast together and to stay motivated.</p>
                      </li>
                      <li>
                          <strong>Determining your target listener</strong>
                          <p>
                              When you’re setting up a podcast, it’s advisable to think about who you want to listen to
                              your shows. Who are you aiming to attract? What do you think your audience is going to
                              look like? If you’re sharing content related to health and fitness, for example, you’re
                              aiming to attract people who have an interest in exercise, eating well and improving their
                              general health and wellbeing. If you’re talking about a hobby, for example, movies or
                              video games, you’ll be looking to appeal to people of a like mind. Once you’ve got a
                              target market in mind, this will help you create content that is relevant to that
                              listener. When you’re putting a podcast together, think about those people you want to
                              lure in, and ask yourself if they would enjoy it. Having listeners in mind will help you
                              to focus on relevant content and prevent you from going off on a tangent, which may not be
                              popular with your subscribers.
                          </p>
                      </li>
                      <li>
                          <strong>Gathering thoughts and ideas</strong>
                          <p>
                              You’ve decided what you want to talk about on your podcast, and you know who you want to
                              attract. Now, you need to focus on the content and figure out how you’re going to add
                              value to your listener’s day. Gather ideas, note down thoughts, take inspiration from blog
                              posts, news headlines, video clips, films, TV programmes or anything else that gets your
                              creative juices flowing and makes you think. When you’re coming up with ideas, you want to
                              ensure that the listener takes something from the podcast, but also that they come back
                              for more. This could be a practical solution or great tips, for example, in the case of a
                              personal trainer with a health and exercise podcast, or in the form of belly laughs or
                              reviews for podcasts that are related to hobbies or those that are designed to distract
                              you from day to day life and put a smile on your face. Every podcast should provide the
                              listener with a reason to tune in again. If you’re new to podcasting, and you’re ready and
                              raring to get started, it’s always good to have ideas for episodes. If you can plan out 10
                              podcasts, for example, this will give you a clear picture of where you’re going, and how
                              you’re going to progress.
                          </p>
                      </li>
                      <li>
                          <strong>Choosing a name</strong>
                          <p>
                              If you search for podcasts online, you’ll find that there are thousands of names that pop
                              up across various different categories. Unless somebody knows about your podcast from
                              reading about it or via a recommendation from a friend or colleague, there’s every chance
                              that it won’t be discovered unless its name appeals to that person. To capture audiences
                              and pique the interest of those looking to listen to a podcast, you need a name that leaps
                              off the page. <a href="https://businessnamegenerator.com/podcast-name-generator/">Choose an original name</a>, which will turn heads. You could opt for a
                              practical name that focuses on the theme of the podcast, you could try and create a pun
                              linked to the subject matter, you could opt for something obscure that conjures up
                              curiosity, or you could simply use your own name. Explore different options, be creative,
                              and make sure that the name is relevant to the podcast and that it’s likely to appeal to
                              listeners.
                          </p>
                      </li>
                  </ol>


                  <h2>Outlining your episodes</h2>

                  <p>
                      When you’re at the point where you’ve got a name, you’ve got a theme, and you know exactly what
                      kind of audience you want to target, you can begin to focus on the structure and format of your
                      podcast episodes.
                  </p>
                  <p>
                      One of the most significant things to think about at this stage is the length of the episode.
                      Podcasts vary in length from around 10-15 minutes to over an hour. Many people choose to listen to
                      podcasts when they’re commuting to and from work, so an episode that lasts 20-30 minutes is a good
                      benchmark. The duration should depend on the content. If you’ve got plenty to say, and you don’t
                      want to cut quality content out, don’t feel that you have to hit that 30 minute mark. There’s
                      nothing wrong with having some episodes that last 25 minutes and some that are 45 minutes. Once
                      you’re up and running, you can ask listeners for feedback related to the length of your episodes,
                      and they will tell you if they think they are too short or long.
                  </p>
                  <p>
                      The next decision you might want to ponder is the frequency of episodes. Most podcasts have weekly
                      updates, and that seems to work well, as many of us plan our schedules based on days and weeks. If
                      you post a new episode every Wednesday, your audience knows when to expect a new podcast. Another
                      option is to follow the pattern of TV series and produce a season of podcasts, for example, one
                      episode every week for 6 weeks. You can vary the frequency, but it’s wise to avoid putting content
                      out there for the sake of it or leaving lengthy gaps between episodes.
                  </p>
                  <p>
                      The format of your podcast is crucial, as it will help you plan each episode and create a pattern
                      that becomes familiar to your listener. Consider how you want each podcast to sound. Are you going
                      to talk for a period of time and share that monologue? Are you hoping to get guests involved and
                      conduct interviews? Are you sharing the mic with a co-host? Do you plan to invite multiple guests
                      to debate or discuss issues or share opinions or tips at the same time? If you are keen to
                      interview people, write a list of potential guests, and start working on getting in touch with
                      them and setting up meetings.
                  </p>
                  <p>
                      Once you’ve got your format sorted, and you know what you want to cover in the first few episodes,
                      you can also start to think about the names of each episode. Like your podcast name, the titles of
                      episodes can help to pull listeners in and encourage them to want to find out more.
                  </p>


                  <p>
                      <img src="/images/recording-your-first-podcast.jpg" alt="Recording your first podcast"/>
                  </p>

                  <h2>Recording your first podcast</h2>

                  <p>
                      Now that your plans are in order, and you’re ready and raring to go, you can turn your attention
                      to recording your first podcast. To do this, you’ll need equipment. If you’re just starting out
                      and you’re familiarising yourself with basic podcast steps, you might not want to invest in
                      high-tech gear just yet.
                  </p>

                  <p>
                      To begin with, you can create a podcast using fairly simplistic technology. A laptop or a computer
                      with a microphone and a reliable Internet connection will be sufficient to record an episode, but
                      it is worth noting that the quality may not be top notch and your options may be limited in terms
                      of what you can do with the recording. If you’re keen to take the next step and you want to
                      improve the sound quality, it’s a good idea to research USB microphones and look for products that
                      have excellent reviews. It’s worth seeing what kind of equipment other podcasters use, for
                      example.
                  </p>
                  <p>
                      To create your podcast, it’s also useful to have headphones so that you can hear yourself speaking
                      and interact and engage with guests and interviewees. On-ear headphones tend to offer better
                      quality than headphone-microphone combinations. To further enhance and improve quality, you can
                      also buy a pop filter.
                  </p>
                  <p>
                      If your podcast is going well, and you’re ready to invest more and really polish your podcast up,
                      you might also want to consider buying a suspension boom. This enables you to speak freely and
                      move your arms around at the same time as enhancing sound quality.
                  </p>
                  <p>
                      Before you can get going, you’ll also need editing and recording software. Some computers have
                      built-in software, for example, GarageBand on Mac, and you can also download free software via the
                      web. There are also programmes you can buy like Adobe Audition.
                  </p>
                  <p>
                      When you’ve got all the kit, and you’re all set to go, hit the record button and speak into the
                      microphone. It’s always useful to have a script or some bullet points in front of you and to have
                      a few practice runs to build your confidence. Try and maintain a conversational tone when you’re
                      speaking. You don’t want the listener to feel like you’re reading notes to them off a page. This
                      is not an audiobook. Sometimes, it can feel a little odd talking into a microphone if you’re not
                      used to it, so it’s helpful to try out your kit in advance of your first recording session.
                  </p>


                  <h2>Production and polishing</h2>

                  <p>
                      Once you’ve got your audio, it’s time to edit it and produce your podcast to ensure that it sounds
                      seamless and slick. This is the part that eliminates the awkward silences and the umms and ahs,
                      and you can also add music if you like. Use editing software like Alitu to iron out hitches and
                      glitches, or if you’ve got money to spend or a lack of time, explore the option of outsourcing.
                      Listen back to the episode a few times to make sure you’re completely happy with it. If you’re
                      adding music, you can use free or subscription services. Examples include Incompetech, Music Radio
                      Creative and Jamendo. To add the final touches to your episode before sharing, add cover art to
                      accompany the name of your podcast and the episode title. It’s also useful to include a meta title
                      and a short description of what that individual episode is about.
                  </p>


                  <h2>Sharing your podcast</h2>

                  <p>
                      To share your podcast and connect with listeners, you’ll need to register with a media sharing
                      service. Popular platforms include Blubrry, Podbean, Libsyn and Pod.ad. Once you’ve done this, you
                      can enable listeners to stream your podcast by signing up with services like iTunes and Google
                      Play. If you have social media accounts or a website, you can share links to your podcast and
                      build your audience.
                  </p>


                  <p>Podcasts are hugely popular, and if you follow these podcast steps, you can get started in no time
                      at all. Figure out what you want to to talk about, define your audience, and work on planning the
                      first few episodes before turning your attention to recording, editing, and sharing.
                  </p>


              </article>


          </Layout>

        )
    }

}

export default Page
